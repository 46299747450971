import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import HeroBanner from '../../components/sections/hero-banner';
import Feature from '../../components/sections/industry/feature-block';
import WhyPatronscan from '../../components/sections/why-patronscan';
import ConnectWithUs from '../../components/sections/connect-with-us';
import { Route } from '../../models/site-links';
import { getDemoLink } from '../../services/demo-selector';
import SEO from '../../components/seo';
import './retail-vape.css';

const RetailVapeIndustry = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopImage: file(relativePath: { eq: "hero-images/fake-ID-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakeID: file(relativePath: { eq: "retail-liquor/fakeID-minor.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      countertop: file(relativePath: { eq: "retail-liquor/pibox-scanner.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      IVAC: file(relativePath: { eq: "retail-liquor/IVAC-in-use.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      IVAC: file(relativePath: { eq: "retail-liquor/IVAC-in-use.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wallmount: file(relativePath: { eq: "retail-liquor/wallmount.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      private: file(relativePath: { eq: "retail-liquor/securePrivateSq.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <SEO
        title="ID Scanners for Retail Stores | Patronscan"
        description="Prevent theft and the sale to minors with Patronscan's ID verification and access control solutions."
      />
      <HeroBanner
        heroImage={data.desktopImage.childImageSharp.fluid}
        heading="ID Scanners for Retail"
        subHeading="Verification solutions to stop thieves, catch fake IDs and prevent age-controlled sales to minors."
        linkText="Get Pricing"
        link={Route.GetPricing}
        buttonId="RetailHero:GetPricing"
      />
      <Feature
        image={data.IVAC.childImageSharp.fluid}
        heading="Reduce theft"
        headingThin="by as much as 93%"
        subHeading="Use our access control solutions to curtail thieves, robberies and minors."
        description="Our identity validation and access control solutions connect to the shared flagged network to keep known thieves out of your store. Save on security costs and stop inventory from slipping away."
        linkText="Get a Quote"
        link={Route.GetPricing}
        buttonId="RetailFeature:ReduceTheft"
      />
      <Feature
        rightAlign
        image={data.wallmount.childImageSharp.fluid}
        heading="Interior and exterior"
        headingThin="installations"
        subHeading="Our rugged, weather-tested solutions works with any storefront, even through blizzards and rainstorms."
        description="Patronscan's access control ID scanners can be installed outside of your store or within entrances vestibule. Speak to us today about an option that works best for you."
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId="RetailFeature:InteriorAndExterior"
      />
      <Feature
        image={data.fakeID.childImageSharp.fluid}
        heading="Spot minors"
        headingThin="and catch fake IDs"
        subHeading="Avoid selling to minors by using Patronscan to spot fake IDs and verify age."
        description="With over 250,000 fake IDs caught around the world, Patronscan is the proven market leader for fake ID detection."
        linkText="Book Demo"
        link={getDemoLink()}
        buttonId="RetailFeature:SpotMinorsFakeIDs"
      />
      <Feature
        rightAlign
        image={data.countertop.childImageSharp.fluid}
        heading="Sleek and modern"
        headingThin="countertop scanners"
        subHeading="Our latest line of ID scanners are fast, accurate and more affordable than ever before."
        description="Less than a square foot, Patronscan's countertop ID scanners take up little space while connecting to either Wifi or Ethernet connections."
        linkText="Contact Us"
        link={Route.TalkToAnExpert}
        buttonId="RetailFeature:SleekAndModern"
      />
      <Feature
        image={data.private.childImageSharp.fluid}
        heading="Secure and"
        headingThin="privacy compliant"
        subHeading="Patronscan is committed to protecting the privacy of your customers."
        description="Routinely audited by privacy commissioners, we ensure our technology keeps private information secure by storing it in top-tier data facilities with strict access control mechanisms in place."
        linkText="Learn More"
        link={Route.Privacy}
        buttonId="RetailFeature:SecurePrivate"
      />
      <WhyPatronscan />
      <div className="vape-connect-spacing">
        <ConnectWithUs
          buttonId="RetailConnect"
          promptText="Request a brochure or speak to one of our representatives to learn why Patronscan is being recommended across the country to prevent losses."
        />
      </div>
    </div>
  );
};

export default RetailVapeIndustry;
